import useFetch from '../../hooks/useFetch';
import Footerli from './footerli';
const Footer = ({ toyotasiempre, logotoyota }) => {
    let llenarlista = 'global/menu-secundario/'
    const [listafooter, error2] = useFetch(llenarlista);

    let url_api = 'global/toyota-info'
    const [respuesta, error] = useFetch(url_api)

    let meta_title = ''
    
    if (respuesta) {
        respuesta.map(c => {
           meta_title = c.meta_title
        }
        )
    }

    return (
        <footer>
            <div className="container-fluid">
                <div className="row footersup destopk">
                    <div className="col-4">
                        <a href={process.env.REACT_APP_URL_DOMAIN} title={meta_title}>
                            <img src={logotoyota} className="imgfooter" alt={meta_title} />
                        </a>
                    </div>
                    <div id="container-grid-footer" className="col-4">
                        <p className="textFooterCenter">Tu eliges la entrada y el plazo que quieres pagar para 
                            conseguir la cuota mensual que más se adapte a tus necesidades</p>
                    </div>
                    <div className="col-4">
                        <a href={process.env.REACT_APP_URL_DOMAIN + 'siemprenuevo'} title="Toyota Siempre Nuevo" target="_blank">
                            <img src={toyotasiempre} className="imgfooter" alt="Toyota Siempre Nuevo" />
                        </a>
                    </div>
                </div>
                <div className="row footerdown" >

                    {
                        listafooter ? (
                            <>
                                {

                                    listafooter.map((c, i) => {
                                        return < Footerli title={c.title.map(h => h.value)} field_url_menu_secundario={c.field_url_menu_secundario} i={i}></Footerli>
                                    }
                                    )
                                }
                            </>
                        ) : (
                            <span> Cargando...</span>
                        )

                    }
                    <hr className='hrfooter'></hr>
                </div>


            </div>
        </footer>
    );
}

export default Footer;